<template>
  <div>
    <div class="login">
      <div class="login">
        <b-button class="btn btn-sm btn-info" v-b-modal.modal-login>Login no Sistema</b-button>
      </div>

      <div class="row">
        <div class="col-lg-4"></div>
        <div class="col-lg-4">
          <img alt="DoctorOn logo" src="../assets/doctorOn.png">
        </div>
        <div class="col-lg-4"></div>
      </div>

      <b-modal
        id="modal-login"
        ref="modal"
        title="Login de Acesso ao Sistema"
        centered
        hideHeaderClose
        okTitle = "Confirmar"
        cancelTitle="Cancelar"
        @show="showModalLogin"
        @hidden="resetModalLogin"
        @ok="handleOkLogin"
      >
        <form ref="form" @submit.stop.prevent="handleSubmitLogin">
          <b-form-group
            :state="emailState"
            label="E-mail"
            label-for="email-input"
            invalid-feedback="Email é requerido"
          >
            <b-form-input id="email-input" v-model="authUser.email" :state="emailState" required></b-form-input>
          </b-form-group>

          <b-form-group
            :state="senhaState"
            label="Senha"
            label-for="senha-input"
            invalid-feedback="Senha é requerida"
          >
            <b-form-input type="password" id="senha-input" v-model="authUser.senha" :state="senhaState" required></b-form-input>
          </b-form-group>

          <b-form-group
            :state="tenantState"
            label="Clínica"
            label-for="tenant-input"
            invalid-feedback="Clínica é requerida"
          >
            <b-form-input id="tenant-input" v-model="tenant" :state="tenantState" required></b-form-input>
          </b-form-group>
        </form>
      </b-modal>
    </div>

    <div class="Perfil">
      <b-modal
        id="modal-select-perfil"
        ref="modal"
        title="Selecione o Perfil de Acesso ao Sistema"
        centered
        hideHeaderClose
        okTitle = "Confirmar"
        cancelTitle="Cancelar"
        @show="resetModalPerfil"
        @hidden="resetModalPerfil"
        @ok="handleOkPerfil"
      >

        <form ref="form" @submit.stop.prevent="handleSubmitPerfil">
          <b-form-group
            :state="perfilState"
            label="Perfis disponíveis"
            label-for="perfil"
            invalid-feedback="É necessário selecionar um Perfil de Acesso"
          >
            <b-form-select id="perfil" v-model="perfilSelecionado" :options="perfis" required></b-form-select>
          </b-form-group>
        </form>
      </b-modal>
    </div>

    <div class="MFA_EnviarSMS">
      <b-modal
        id="modal-enviar-SMS"
        ref="modal"
        title="Enviar Código de Acesso"
        centered
        hideHeaderClose
        okTitle = "Confirmar"
        cancelTitle="Cancelar"
        @show="showModalMFA_EnviarSMS"
        @hidden="resetModalMFA_EnviarSMS"
        @ok="handleOkMFA_EnviarSMS"
      >
        <form ref="form" @submit.stop.prevent="handleSubmitMFA_EnviarSMS">
          <b-form-group
            :state="celularState"
            label="Celular"
            label-for="celular-input"
            invalid-feedback="Celular é requerido"
          >
            <b-form-input id="celular-input" v-model="celular" :state="celularState" required></b-form-input>
          </b-form-group>
        </form>
      </b-modal>
    </div>

    <div class="MFA_VerificarCodigo">
      <b-modal
        id="modal-verificar-Codigo"
        ref="modal"
        title="Verificar Código de Acesso"
        centered
        hideHeaderClose
        okTitle = "Confirmar"
        cancelTitle="Cancelar"
        @show="showModalMFA_VerificarCodigo"
        @hidden="resetModalMFA_VerificarCodigo"
        @ok="handleOkMFA_VerificarCodigo"
      >
        <form ref="form" @submit.stop.prevent="handleSubmitMFA_VerificarCodigo">
          <b-form-group
            :state="codigoMFAState"
            label="Código de Acesso"
            label-for="codigoMFA-input"
            invalid-feedback="Código de Acesso é requerido"
          >
            <b-form-input id="codigoMFA-input" v-model="codigoMFA" :state="codigoMFAState" required></b-form-input>
          </b-form-group>
        </form>
      </b-modal>
    </div>

    <div class="Mensagem">
      <b-modal id="modal-mensagem" size="md" no-close-on-esc hide-header-close ok-only centered cancel-title="Cancelar" ok-title="Fechar" title="DoctorOn">{{mensagem}}</b-modal>
      <b-modal id="modal-mensagem-login" size="md" no-close-on-esc hide-header-close ok-only centered cancel-title="Cancelar" ok-title="Fechar" title="DoctorOn">{{mensagemLogin}}</b-modal>
      <b-modal id="modal-mensagem-tenant" size="md" no-close-on-esc hide-header-close ok-only centered cancel-title="Cancelar" ok-title="Fechar" title="DoctorOn">{{mensagemTenant}}</b-modal>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import usuarios from '@/mixins/usuarios'
import profissionais from '@/mixins/profissionais'
import global from '@/mixins/global'
import configs from '@/mixins/configs'
import funcoes from '@/utils/funcoes'
import errors from '@/utils/errors'

export default {
  mixins : [usuarios, profissionais, configs, global],
  data() {
      return {
        erro : "",

        emailState: null,
        senhaState: null,

        tenant : '',
        tenantState : null,
        tenantConfirmado : false,

        celular : '',
        celularState : null,

        codigoMFA : "",
        codigoMFAState : null,

        codigoMFAGerado : 0,

        authUser : {email : '', senha : ''},
        authResult : {},
        usuarioLogadoDados : {},

        registroAcesso : {},
        usuarioPerfis : [],

        perfis : [],
        perfilSelecionado : null,
        perfilState : null,

        meuPerfil : null,

        horarioPermitidoPerfil : [],

        configSelecionado : null,

        mensagem : "",
        mensagemLogin : "",
        mensagemTenant : ""
      }
  },
  computed: {
    ...mapGetters({
    })
  },
  methods: {
    ...mapActions({
      setAutenticacao : "global/setUsuarioLogadoAutenticacao",
      setInstanciaBancoDeDados : "global/setInstanciaBancoDeDados",
      setUsuarioLogadoDados : "global/setUsuarioLogadoDados",
      setMenuOptions : "global/setMenuOptions",
      setUnformatedMenuOptions : "global/setUnformatedMenuOptions",
      setAssinanteSimpro : "global/setAssinanteSimpro",
      setAssinanteBrasindice : "global/setAssinanteBrasindice",
      setRenderMenuNumber : "global/setRenderMenuNumber"
    }),
    async Autenticacao() {
      await this.VerificarTenant();
    },
    async VerificarTenant() {
      await this.$http.get(`/v1.0/tenants/${this.tenant}/verificarTenant`)
      .then(resp => {
        let resultado = resp.data;

        this.erro = "";

        this.tenantConfirmado = true;
        this.setInstanciaBancoDeDados(resultado.instanciaBancoDeDados);

        if (resultado.status.indexOf('Ok') == -1) {
          this.mensagemTenant = resultado.status;
          this.$bvModal.show("modal-mensagem-tenant");

          if (resultado.status.indexOf('Bloqueio') != -1) {
            this.tenantConfirmado = false;
          }
        }
      })
      .then(() => {
        if (this.tenantConfirmado) {
          this.Login();
        }
      })
      .catch(() => {
        this.mensagemTenant = 'Autenticação Clínica: Clínica informada é inválida ou Serviço não está disponível';
        this.$bvModal.show("modal-mensagem-tenant");

        return false;
      });
    },
    abrirTelaLogin() {
      this.$bvModal.show("modal-login");
    },
    async Login() {
      await this.$http.post(`/v1.0/autenticacao/${this.tenant}/login`, this.authUser)
      .then(resp => {
        this.authResult = resp.data;
        this.setAutenticacao(this.authResult);
      })
      .catch(error => {
        this.setAutenticacao(null);
        this.setUsuarioLogadoDados(null);

        if (error.response.status != 500) {
          this.mensagemLogin = error.response.data;
        } else {
          this.mensagemLogin = 'Autenticação Usuário: Clínica informada é inválida ou Serviço não está disponível';
        }

        this.$bvModal.show("modal-mensagem-login");        

        return;
      })

      if (this.authResult.id == undefined) {
        return false;
      }

      this.erro = "";
      await this.carregarUsuarioPeloId(this.tenant, 'S');

      if (this.erro == "") {
        await this.carregarUsuarioPerfil(this.tenant);

        if (this.erro == "") {
          if (this.usuarioPerfis.length==0) {
            this.mensagem = "Não foi localizado um Perfil de Acesso para o Usuário.";
            this.$bvModal.show("modal-mensagem");
            return;
          }

          //if (this.usuarioPerfis.length == 1) {
          //  await this.verificarHorarioUtilizacao(this.usuarioPerfis[0]);
          //} else {
          //  await this.selecionarPerfil();
          //}

          if (this.usuarioPerfis.length > 1) {
            await this.selecionarPerfil();
          } else {
            this.meuPerfil = this.usuarioPerfis[0]
          }

          if (this.meuPerfil.mfa == 'S') {
            //verificar MFA
            //await this.$bvModal.show("modal-enviar-SMS");
          }

          await this.verificarHorarioUtilizacao(this.meuPerfil);
        } else {
          this.mensagem = "Perfil do Usuário: " + this.erro;
          this.$bvModal.show("modal-mensagem");

          return
        }
      } else {
        this.mensagem = "Usuário: " + this.erro;
        this.$bvModal.show("modal-mensagem");
      }
    },
    async selecionarPerfil() {
      this.usuarioPerfis.forEach(usuarioPerfil => {
        this.perfis.push(usuarioPerfil.perfil.tipo);
      });

      this.$bvModal.show("modal-select-perfil");
    },
    async verificarHorarioUtilizacao(acessarComPerfil) {
      await this.carregarHorarioPermitidoPerfil(this.tenant, acessarComPerfil.id);

      if (this.horarioPermitidoPerfil.id == undefined) {
        this.mensagem = "Você não possui horário disponível para esse dia da Semana.";
        this.$bvModal.show("modal-mensagem");        
        return;
      }

      if (!funcoes.verificarHorarioPerfil(this.horarioPermitidoPerfil.horarioInicial, this.horarioPermitidoPerfil.horarioFinal)) {
        this.mensagem = `Você está liberado para utilizar o sistema das ${this.horarioPermitidoPerfil.horarioInicial} às ${this.horarioPermitidoPerfil.horarioFinal}`;
        this.$bvModal.show("modal-mensagem");        
        return;
      }

      await this.finalizarLogin(acessarComPerfil);
    },
    async finalizarLogin(acessarComPerfil) {
      await this.registrarAcesso(this.tenant, this.usuarioLogadoDados.id, acessarComPerfil.perfil.tipo);

      if (this.erro == "") {
        this.usuarioLogadoDados.registroAcesso = {...this.registroAcesso};
        this.usuarioLogadoDados.perfilAcesso = {...acessarComPerfil};
        this.usuarioLogadoDados.horarioPermitido = {...this.horarioPermitidoPerfil};
        this.usuarioLogadoDados.tenant = this.tenant;

        this.setUsuarioLogadoDados(this.usuarioLogadoDados);

        await this.carregarConfigs(this.usuarioLogadoDados.tenant);
        this.setAssinanteSimpro(this.configSelecionado.assinanteSimpro);
        this.setAssinanteBrasindice(this.configSelecionado.assinanteBrasindice);

        await this.montarMenu();
        this.$router.push("/frmPrincipal");
      } else {
        this.mensagem = "Não foi possível registrar o Acesso do Usuário.";
        this.$bvModal.show("modal-mensagem");        
      }
    },
    handleOkLogin(bvModalEvt) {
        bvModalEvt.preventDefault()
        this.handleSubmitLogin()
    },  
    handleSubmitLogin() {
        // Exit when the form isn't valid
        if (!this.checkFormValidity()) {
          return
        }
        // Push the name to submitted names
        // this.submittedNames.push(this.email)
        // Hide the modal manually

        this.$nextTick(() => {
            this.$refs.modal.hide();
        })
        
        this.Autenticacao();
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()

      this.emailState = valid ? true : false;
      this.senhaState = valid ? true : false;
      this.tenantState = valid ? true : false;

      return valid
    },
    showModalLogin() {
      this.emailState = null;
      this.senhaState = null;
      this.tenantState = null;

      this.authUser = {email : '', senha : ''}
    },
    resetModalLogin() {
      // this.emailState = null;
      // this.senhaState = null;
      // this.tenantState = null;

      // this.authUser = {email : '', senha : ''}
    },
    handleOkPerfil() {
      this.handleSubmitPerfil();
    },
    handleSubmitPerfil() {
      this.meuPerfil = {...this.usuarioPerfis.find(perfis => perfis.perfil.tipo == this.perfilSelecionado)};
    },
    resetModalPerfil() {
      this.perfilSelecionado = null;
    },

    handleOkMFA_EnviarSMS() {
      this.handleSubmitMFA_EnviarSMS();
    },
    handleSubmitMFA_EnviarSMS() {
      let codigo = Math.random()*10000;
      this.codigoMFAGerado = codigo.toFixed(0);

      //------------------ enviar SMS com Código MFA Gerado
      let mensagem = `Digite ${this.codigoMFAGerado} para acessar o sistema.`;
      let urlComplemento = '/sends?mensagem='+mensagem+'&telefone='+this.celular+'&ID='+'R26'+'&chave=0';

      this.$httpSMS.get(urlComplemento)
      .then(() => {
        this.erro = "";
      })
      .catch(error => {
        this.erro = errors.tratarErros(error);
        alert(this.erro);
      })
    },
    showModalMFA_EnviarSMS() {
      alert('aqui... showMFA_EnviarSMS')
      this.codigoMFA = "";
    },

    resetModalMFA_EnviarSMS() {
      alert('aqui... resetMFA_EnviarSMS')
      this.codigoMFA = "";
    },

    handleOkMFA_VerificarCodigo() {
      this.handleSubmitMFA_VerificarCodigo();
    },
    handleSubmitMFA_VerificarCodigo() {
      if (this.codigoMFA != this.codigoMFAGerado) {
        this.mensagem = `Código Inválido`;
        this.$bvModal.show("modal-mensagem");

        return false;
      }

      return true;
    },
    showModalMFA_VerificarCodigo() {
      this.codigoMFA = "";
    },
    resetModalMFA_VerificarCodigo() {
      this.codigoMFA = "";
    },

    async montarMenu() {
      let grauPermissao = this.usuarioLogadoDados.perfilAcesso.perfil.grauPermissao;
      let nivelPermissaoViewInferiores = this.usuarioLogadoDados.perfilAcesso.nivelPermissaoViewInferiores;

      let treeMenu = [];
      let menu = null;
      let itemMenu = null;
      let filhos = [];
      let adicionados = [];

      await this.$http.get(`/v1.0/sysmenus/${grauPermissao}/${nivelPermissaoViewInferiores}`)
        .then(resp => {
          menu = resp.data;

          menu.forEach(item => {
            if (adicionados.indexOf(item.id)>=0) {
              return true;
            }

            itemMenu = {id : item.id, text : item.descricao};
            filhos = this.verificarFilhos(item.opcao, menu, adicionados);

            if (filhos.length > 0) {
              itemMenu.children = [...filhos]
            }

            treeMenu.push(itemMenu);
          });

          //console.table(treeMenu)

          this.setMenuOptions(treeMenu);
          this.setUnformatedMenuOptions(menu);
          this.setRenderMenuNumber(1);
        })
        .catch(error => {
            this.mensagem = errors.tratarErros(error);
            this.$bvModal.show("modal-mensagem");
        })
    },
    verificarFilhos(opcao, menu, adicionados) {
      let filhos = menu.filter(m => m.parent == opcao);
      let submenu = [];

      filhos.forEach(filho => {
        if (filho.haschildren==0) {
          submenu.push({
            id : filho.id,
            text : filho.descricao,
            data : {rota : filho.rota}
          });
        } else {
          submenu.push({
            id : filho.id,
            text : filho.descricao,
            children : [...this.verificarFilhos(filho.opcao, menu, adicionados)]
          });
        }

        adicionados.push(filho.id);
      })

      return submenu;
    }
  },
  created() {
    this.showToggleMenu(false);
    this.showUserDropDown(false);
    this.setAutenticacao(null);
  },
  mounted() {
    this.abrirTelaLogin();
  },
}
</script>

<style scoped>
   .logoDoctorOn {
     width : auto;
     height:  auto;
   }
</style>
